import { defineStore } from 'pinia'
import type { Category, Chapter } from '~/types/demegro'

export const useChapterStore = defineStore('chapterStore', () => {
    const { data: chapters, status } = useLazyFetch<Chapter[]>('/api/chapters/list/', {
        key: 'chapters-list',
        server: true,
        default: () => [],
        onResponseError({ response }) {
            console.error('Error fetching chapters', response)
            throw new Error(response.statusText)
        },
    })

    const loading = computed(() => status.value === 'pending')

    const getChapterBySlug = computed(() => (slug: string): Chapter | undefined => {
        if (!chapters.value?.length || !slug) {
            return undefined
        }

        return findChapterBySlug(slug)
    })

    function findChapterBySlug(slug: string): Chapter | undefined {
        if (!chapters.value?.length || !slug)
            return undefined

        const normalizedSlug = normalizeSlug(slug)
        return chapters.value.find(chapter => normalizeSlug(chapter.slug) === normalizedSlug)
    }

    const getCategoryBySlug = computed(() => (slug: string): Category | undefined => {
        if (!chapters.value?.length || !slug)
            return undefined

        return findCategoryBySlug(slug) ?? undefined
    })

    function findCategoryBySlug(slug: string): Category | undefined {
        if (!chapters.value?.length || !slug)
            return undefined

        const normalizedSlug = normalizeSlug(slug)
        for (const chapter of chapters.value) {
            const category = chapter.categories.find(category => normalizeSlug(category.slug) === normalizedSlug)
            if (category) {
                return category
            }
        }
        return undefined
    }

    const getChapters = (count: number | null = null) => {
        if (!chapters.value) {
            return []
        }

        if (count) {
            return chapters.value.slice(0, count)
        }
        return chapters.value
    }

    const getRandomChapters = (count: number = 6): Chapter[] => {
        if (!chapters.value) {
            return []
        }

        const shuffled = [...chapters.value].sort(() => 0.5 - Math.random())
        return shuffled.slice(0, Math.min(count, chapters.value.length))
    }

    const getRandomCategories = (count: number = 6): Category[] => {
        if (!chapters.value) {
            return []
        }

        const categories = chapters.value.flatMap(chapter => chapter.categories)

        if (categories.length === 0) {
            return []
        }

        const shuffled = [...categories].sort(() => 0.5 - Math.random())
        return shuffled.slice(0, Math.min(count, categories.length))
    }

    const filterAndLimitChapters = (showAll: boolean = false, limit: number = 6) => {
        if (!chapters.value)
            return []

        const chapterList = []
        const seenFirstLetters = new Set()

        for (const chapter of chapters.value) {
            if (showAll) {
                chapterList.push(chapter)
            }
            else {
                const firstLetter = chapter.title.charAt(0).toUpperCase()
                if (chapter.image && !seenFirstLetters.has(firstLetter)) {
                    chapterList.push(chapter)
                    seenFirstLetters.add(firstLetter)
                }
            }
        }

        return showAll ? chapterList : chapterList.slice(0, limit)
    }

    return {
        chapters,
        loading,
        getChapterBySlug,
        getCategoryBySlug,
        getChapters,
        getRandomChapters,
        getRandomCategories,
        filterAndLimitChapters,
    }
})

if (import.meta.hot) {
    import.meta.hot.accept(acceptHMRUpdate(useChapterStore, import.meta.hot))
}
